import { mapGetters, mapActions } from 'vuex'
import _orderBy from 'lodash/orderBy'

const prescriptionRecord = {
  created() {
    !this.prescriptions.length && this.getMyPrescriptions()
  },
  data() {
    return {
      sortBy: 'startDate',
      sortDirection: 'desc',
    }
  },
  computed: {
    ...mapGetters('healthProfile', [
      'isError',
      'isLoadingPrescriptionRecord',
      'prescriptions',
    ]),
    sortedPrescriptions() {
      return _orderBy(this.prescriptions, [this.sortBy], [this.sortDirection])
    },
  },
  methods: {
    ...mapActions('healthProfile', ['getMyPrescriptions']),
  },
}

export default prescriptionRecord
