<template>
  <div class="w-full max-w-3xl py-20 mb-200">
    <h2>
      {{ $t('prescription_record.title') }}
    </h2>
    <Spinner v-if="isLoadingPrescriptionRecord" />
    <p v-else-if="isError">
      {{ $t('prescription_record.error_loading') }}
    </p>
    <div v-else>
      <div class="py-20 flex justify-end">
        <div class="flex items-center">
          <div class="flex-col items-center">
            <label class="ml-4 font-bold text-sm" for="column-sort-selector">
              {{ $t('prescription_record.sort_by') }}
            </label>
            <FormListPicker
              id="column-sort-selector"
              v-model="sortBy"
              name="column-sort-selector"
              :data="columnsDropdown"
              class="w-150 mr-20"
            />
          </div>
          <div class="flex-col items-center">
            <label class="ml-4 font-bold text-sm" for="sort-direction-selector">
              {{ $t('prescription_record.sort_order') }}
            </label>
            <FormListPicker
              id="sort-direction-selector"
              v-model="sortDirection"
              name="sort-direction-selector"
              :data="sortDirectionsDropdown"
              class="w-150 mr-20"
            />
          </div>
        </div>
      </div>
      <table class="w-full">
        <thead class="px-30 border-b border-grey-200 font-semibold">
          <th class="th">{{ $t('prescription_record.name_col') }}</th>
          <th class="th">{{ $t('prescription_record.filled_on_col') }}</th>
          <th class="th">{{ $t('prescription_record.days_supply_col') }}</th>
        </thead>
        <p v-if="!prescriptions.length" class="p-20">
          {{ $t('prescription_record.no_results') }}
        </p>
        <tbody v-else>
          <tr
            v-for="(med, idx) in sortedPrescriptions"
            :key="`med-${idx}`"
            :class="{ 'bg-grey-50': idx % 2 }"
          >
            <td class="td">{{ med.name }}</td>
            <td class="td">{{ med.filledOn }}</td>
            <td class="td">{{ med.daysSupply }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FormListPicker from '@components/common/forms/FormListPicker'
import Spinner from '@components/common/Spinner'
import prescriptionRecord from '@platform-shared/mixins/prescriptionRecord'

export default {
  components: { Spinner, FormListPicker },
  mixins: [prescriptionRecord],
  data() {
    return {
      columnsDropdown: [
        {
          value: 'name',
          display: this.$t('prescription_record.name_col'),
        },
        {
          value: 'startDate',
          display: this.$t('prescription_record.filled_on_col'),
        },
        {
          value: 'daysSupply',
          display: this.$t('prescription_record.days_supply_col'),
        },
      ],
      sortDirectionsDropdown: [
        {
          value: 'asc',
          display: this.$t('common.ascending'),
        },
        {
          value: 'desc',
          display: this.$t('common.descending'),
        },
      ],
    }
  },
}
</script>

<style scoped>
.th {
  @apply py-12 text-left;
}
.td {
  @apply py-16 border-b border-grey-200;
}
</style>
